import { Box, Typography } from "@mui/joy";
import { NavHeader, SquashBCHeader } from "./Header";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useGetClubQuery } from "../apis/clubhub.api";
import { useGetVenueQuery } from "../apis/squashFinder.api";

const ClubHeader = () => {
	const venueName = useParams<{ venueName: string }>().venueName;
	const location = useLocation();

	const { data: club } = useGetClubQuery(venueName || "", {
		skip: !venueName,
	});

	const { data: venue } = useGetVenueQuery(venueName || "");
	const currentTab = useMemo(() => {
		return location.pathname.includes("gallery")
			? "Gallery"
			: location.pathname.includes("courtbooking")
			? "Court Booking"
			: "Home";
	}, [location.pathname]);

	return (
		<Box
			sx={{
				minHeight: 200,
				borderBottom: "10px solid #1f3a6e",
				backgroundImage: `url(${require("../assets/hero1.png")})`,
				backgroundSize: "fill",
				pb: 5,
			}}>
			{club?.parentOrganization === 10349 ? (
				<SquashBCHeader
					transparent
					style={{
						position: "fixed",
						top: 0,
						backgroundColor: `#00255B`,
						width: "100%",
						transition: "background-color 0.1s",
						zIndex: 1,
						height: 80,
					}}
					// on scroll, make the header text color change from white to black
					// color={dynamicHeight > 10 ? "black" : "white"}
					showLogout={false}
					disabledColor={"rgba(255, 255, 255, 0.5)"}
					currentTab={currentTab}
				/>
			) : (
				<NavHeader transparent showLogout={false} />
			)}
			<Box
				sx={{
					width: {
						xs: "90%",
						md: "50%",
					},
					mx: {
						xs: "auto",
						md: 10,
					},
					mt: club?.parentOrganization === 10349 ? 10 : 0,
					pt: club?.parentOrganization === 10349 ? 2 : 0,
				}}>
				{club?.logo && (
					<img
						src={"https://storage.googleapis.com/activscout/" + club.logo}
						alt="logo"
						width={60}
						height={60}
					/>
				)}
				<Typography
					sx={{
						color: "black",
						fontWeight: "600",
						fontSize: {
							xs: 36,
							sm: 40,
						},
						mb: {
							xs: 1,
							sm: 2,
						},
					}}>
					{venue?.name?.toUpperCase()}
				</Typography>
				<Typography
					fontSize={18}
					sx={{
						color: "#7f7f7f",
					}}>
					{venue?.physical_address}
				</Typography>
			</Box>
		</Box>
	);
};

export default ClubHeader;
