import { Box, Table, Typography, Input, Button } from "@mui/joy";
import React, { useEffect, useState } from "react";
import SectionHeader from "./SectionHeader";
import { useParams } from "react-router-dom";
import { useGetClubQuery, useSaveClubDetailsMutation } from "../apis/club.api";

interface FeesAndMembershipsProps {
	editable: boolean;
}

const TypographyBlue = (props: any) => (
	<Typography
		{...props}
		sx={{
			color: "#1F3A6E",
			fontWeight: "500",
		}}>
		{props.children}
	</Typography>
);

const FeesAndMemberships: React.FC<FeesAndMembershipsProps> = ({
	editable,
}) => {
	const initialFeesData = {
		dropInFees: [
			{ type: "Adult/Senior (19+)", dropIn: "0", allDay: "0" },
			{ type: "Youth (13 - 18)", dropIn: "0", allDay: "0" },
			{ type: "Child (4 - 12)", dropIn: "0", allDay: "0" },
			{ type: "Family", dropIn: "0", allDay: "0" },
		],
		multiTimePasses: [
			{
				type: "Adult/Senior (19+)",
				"10xPass": "0",
				"20xPass": "0",
				"30xPass": "0",
			},
			{
				type: "Youth (13 - 18)",
				"10xPass": "0",
				"20xPass": "0",
				"30xPass": "0",
			},
			{
				type: "Child (4 - 12)",
				"10xPass": "0",
				"20xPass": "0",
				"30xPass": "0",
			},
			{
				type: "Family",
				"10xPass": "0",
				"20xPass": "0",
				"30xPass": "0",
			},
		],
		monthlyPasses: [
			{
				type: "Adult/Senior (19+)",
				monthly: "0",
				"3Month": "0",
				"6Month": "0",
				annual: "0",
			},
			{
				type: "Youth (13 - 18)",
				monthly: "0",
				"3Month": "0",
				"6Month": "0",
				annual: "0",
			},
			{
				type: "Child (4 - 12)",
				Monthly: "0",
				"3Month": "0",
				"6Month": "0",
				Annual: "0",
			},
			{
				type: "Family",
				monthly: "0",
				"3Month": "0",
				"6Month": "0",
				annual: "0",
			},
			{ type: "Off-Peak Pass (Adult)", monthly: "0", "3Month": "0" },
			{ type: "Off-Peak Pass (Youth)", monthly: "0", "3Month": "0" },
		],
	};

	const [feesData, setFeesData] = useState<any>(initialFeesData);

	const [updateFeesData] = useSaveClubDetailsMutation();

	const { venueName } = useParams<{ venueName: string }>();

	const { data: club } = useGetClubQuery(venueName || "", {
		skip: !venueName,
	});

	const feeData = club?.fees;

	useEffect(() => {
		if (venueName && editable) {
			setFeesData((prevFees: any) => ({
				...prevFees,
				clubId: venueName,
			}));
		}
	}, [venueName, editable]);

	useEffect(() => {
		if (feeData) {
			setFeesData(feeData);
		}
	}, [feeData]);

	const onSave = async () => {
		try {
			await updateFeesData({
				id: club?._id || "",
				data: {
					// @ts-ignore
					fees: feesData,
				},
			}).unwrap();

			alert("Fees and memberships saved successfully");
		} catch (e) {
			console.error(e);
		}
	};

	// Handle input change for fees
	const handleInputChange = (
		category: string,
		index: number,
		key: string,
		value: string
	) => {
		if (isNaN(Number(value))) return;
		setFeesData((prevFees: any) => ({
			...prevFees,
			[category]: prevFees[category].map((item: any, idx: number) =>
				idx === index ? { ...item, [key]: value } : item
			),
		}));
	};

	// Render table with either editable inputs or static text depending on the "editable" prop
	const renderTable = (
		title: string,
		data: any[],
		columns: any[],
		category: string
	) => {
		// Filter out columns with no data in any row
		const filteredColumns = columns.filter((col) =>
			data.some((item) => item[col] && item[col] !== "0")
		);

		// Return null if no valid columns are left
		if (filteredColumns.length === 0) return null;

		return (
			<Box sx={{ marginTop: 1 }}>
				<table>
					<thead
						style={{
							backgroundColor: "white",
						}}>
						<tr
							style={{
								backgroundColor: "white",
							}}>
							<th
								style={{
									width: 280,
									backgroundColor: "white",
									textAlign: "left",
									paddingLeft: 10,
									height: 50,
								}}>
								<TypographyBlue>{title}</TypographyBlue>
							</th>
							{filteredColumns.map((col, idx) => (
								<th
									key={idx}
									style={{
										width: 120,
										backgroundColor: "white",
										textAlign: "left",
										paddingLeft: 30,
									}}>
									<TypographyBlue>{col}</TypographyBlue>
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{data.map((item, index) => (
							<tr key={index}>
								<td style={{ padding: 0 }}>
									<Box
										sx={{
											background: "#D0d0d0",
											width: 220,
											padding: 1.3,
											paddingLeft: 2,
										}}>
										<TypographyBlue>{item.type}</TypographyBlue>
									</Box>
								</td>
								{filteredColumns.map((col, idx) => (
									<td key={idx}>
										{editable ? (
											<Box
												sx={{
													display: "flex",
													alignItems: "center",
													gap: 0.5,
												}}>
												<Typography>$</Typography>
												<Input
													type="text"
													value={item[col]}
													onChange={(e) =>
														handleInputChange(
															category,
															index,
															col,
															e.target.value
														)
													}
													sx={{
														width: 220,
														padding: 1.3,
													}}
												/>
											</Box>
										) : item[col] ? (
											<Box
												sx={{
													width: 100,
													padding: 1.3,
													background: "#D0d0d0",
													textAlign: "center",
												}}>
												<TypographyBlue>
													${parseFloat(item[col]).toFixed(2)}
												</TypographyBlue>
											</Box>
										) : null}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</Box>
		);
	};

	if (!feeData && !editable) return null;

	return (
		<Box
			sx={{
				mt: editable ? 0 : 7,
				pb: 7,
			}}>
			<SectionHeader title={"FEES AND MEMBERSHIPS"} />
			{editable ? null : (
				<>
					<Box display={"flex"} justifyContent={"center"} mb={4} mt={1}>
						<Typography
							width={{
								xs: "auto",
								sm: "60%",
								color: "#7F7F7F",
							}}
							level="body-xs"
							textAlign="center">
							This is crowd sourced data and may not be current or correct. You
							may wish to visit the venues official website for up-to-date
							information. If you are an administrator of this facility, please
							claim it to ensure that the content being displayed is correct and
							current
						</Typography>
					</Box>
				</>
			)}
			<Box
				sx={{
					overflowX: "auto",
					width: {
						xs: "100%",
						lg: "fit-content",
					},
					display: "flex",
					flexDirection: "column",
				}}>
				{feesData["Drop-In Fees"]?.length
					? renderTable(
							"Drop-in Fees",
							feesData["Drop-In Fees"],
							["Drop-In", "All Day"],
							"Drop-In Fees"
					  )
					: null}
				{feesData["Multi-Time Passes"]?.length
					? renderTable(
							"Multi-Time Passes",
							feesData["Multi-Time Passes"],
							["10x Pass", "20x Pass", "30x Pass"],
							"Multi-Time Passes"
					  )
					: null}
				{feesData["Monthly Passes"]?.length
					? renderTable(
							"Monthly Passes",
							feesData["Monthly Passes"],
							["Monthly", "3 Month", "6 Month", "Annual"],
							"Monthly Passes"
					  )
					: null}

				{editable ? (
					<Button
						onClick={onSave}
						sx={{
							width: 200,
							mr: 1,
							mt: 3,
							alignSelf: "flex-end",
						}}>
						SAVE
					</Button>
				) : null}
			</Box>
		</Box>
	);
};

export default FeesAndMemberships;
